<template>
  <div class="relative block border border-transparent bg-white shadow-md rounded hover:border-prasset-green-400 focus:border-prasset-green-400 focus:outline-none">

    <div class="absolute top-0 right-0 mt-2 mr-2 z-1">
      <button @click.stop="$refs.flyout.toggleFlyout" type="button" class="button button--clear button--small button--clamp">
        <i class="ri-more-2-fill"></i>
      </button>
      <Flyout ref="flyout" class="py-2 rounded z-1 flex flex-col justify-end bg-white shadow-md absolute top-full right-0 mt-2 mr-1">
        <button type="button" @click="confirmDelete(website)" class="flex focus:outline-none whitespace-no-wrap py-2 px-4 leading-none text-prasset-gray-800 hover:bg-gray-100 focus:bg-gray-100 hover:text-prasset-green-500">
          <i class="ri-delete-bin-line mr-2" />
          <span>{{ $t('delete') }}</span>
        </button>
      </Flyout>
    </div>

    <RouterLink :to="getWebsiteRoute(website)" class="block relative pb-2/3 overflow-hidden bg-gray-100 rounded">
      <img v-if="website.previewImage" :src="website.previewImage" class="object-cover absolute top-0 h-full w-full" />
    </RouterLink>

    <RouterLink :to="getWebsiteRoute(website)" class="block absolute bottom-0 left-0 right-0 bg-white p-4 bg-opacity-75 text-sm rounded-b leading-none text-prasset-gray-800 truncate">
      {{ website.name }}
    </RouterLink>
  </div>
</template>

<script>
import useWebsite from '@/compositions/useWebsite';
import EventBus from '@/eventbus';
import Flyout from '@/components/Flyout';

export default {
  name: 'WebsiteCard',

  components: {
    Flyout,
  },

  props: {
    website: {
      type: Object,
      default: null,
    },
  },

  setup(props, context) {
    const { project_id } = context.root.$route.params;
    const { deleteWebsite, unloadWebsite } = useWebsite();

    const getWebsiteRoute = (website) => {
      return {
        name: website.pages.length > 0 ? 'website-page' : 'website-page-create',
        params: {
          website_id: website.id,
          page_id: website.pages.length > 0 ? website.pages[0].id : 'empty',
          type: 'websites',
        },
      };
    };

    const confirmDelete = (website) => {
      const website_id = website.id;
      EventBus.$emit('confirm:open', {
        title: 'Let op',
        main: `Weet je zeker dat je ${website.name} wilt verwijderen?`,
        confirmButton: 'Bevestigen',
        cancelButton: 'Annuleren',
        onConfirm() {
          deleteWebsite(project_id, website.id);
          unloadWebsite(website_id);
        },
      });
    };

    return {
      confirmDelete,
      getWebsiteRoute,
    };
  },
};
</script>
