<template>
  <div class="bg-white">
    <PageHeader :title="$tuf('websites')" :description="$tuf('description_website_websites')" />

    <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-flow-row gap-6 pl-5 pr-5 pb-5">
      <RouterLink :to="{ name: 'website-create' }" class="relative block text-prasset-green-500 rounded transition duration-100 transition-colors bg-prasset-gray-100 hover:bg-prasset-green-400 hover:text-white focus:bg-prasset-green-400 focus:text-white focus:outline-none">
        <span class="block relative pb-2/3"></span>
        <span class="block absolute inset-0 flex justify-center items-center text-5xl leading-none">
          +
        </span>
      </RouterLink>
      <WebsiteCard v-for="website in websites" :key="website.id" :website="website" />
    </div>

    <RouterView /> <!-- for sidebar -->
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api';
import PageHeader from '@/modules/core/views/components/PageHeader';
import WebsiteCard from '@/modules/website/views/components/WebsiteCard';
import useWebsite from '@/compositions/useWebsite';

export default {
  name: 'Websites',

  components: {
    PageHeader,
    WebsiteCard,
  },

  setup(props, { root }) {
    const { project_id } = root.$route.params;
    const { websites, loadWebsites } = useWebsite();

    onMounted(async () => {
      loadWebsites(project_id);
    });

    return {
      websites,
    };
  },
};
</script>
